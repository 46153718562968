import { PanelMenu } from 'primereact/panelmenu';
import { useState } from 'react';

const SettingsLayout = ({ children, expandedKeys = [] }) => {
    const expanded = (key) => {
        return expandedKeys.indexOf(key) >= 0
    }

    const [ items ] = useState([
        {
            expanded: expanded('auctions'),
            label: 'Auctions',
            items: [
                {
                    label: 'Archive',
                    url: '/admin/settings/auctions/archive'
                },
                {
                    label: 'Categories',
                    url: '/admin/categories'
                },
                {
                    label: 'Bid Increments',
                    url: '/admin/settings/auctions/bid-increments'
                },
            ]
        },
        {
            expanded: expanded('company'),
            label: 'Company',
            items: [
                {
                    label: 'Privacy Policy',
                    url: '/admin/settings/company/privacy-policy'
                },
                {
                    label: 'Terms',
                    url: '/admin/settings/company/terms'
                },
                {
                    label: 'Types',
                    url: '/admin/settings/company-types'
                },
            ]
        },
        {
            expanded: expanded('finance'),
            label: 'Finance',
            items: [
                {
                    expanded: expanded('invoices'),
                    label: 'Invoices',
                    items: [
                        {
                            label: 'Max Charge Value',
                            url: '/admin/settings/invoices/max-charge-value'
                        },
                        {
                            label: 'Premiums',
                            url: '/admin/settings/invoices/premiums'
                        },
                        {
                            label: 'Expenses',
                            url: '/admin/settings/invoices/expenses'
                        },
                        {
                            label: 'Sales Tax',
                            url: '/admin/settings/invoices/taxes'
                        }
                    ]
                },
                {
                    label: 'Payment Types',
                    url: '/admin/settings/payment-types'
                },
                // {
                //     label: 'Expense Groups',
                // }
            ]
        },
    ])

    return (
        <div className="flex gap-6 mt-6">
            <PanelMenu model={items}
                       multiple
                       className="w-60 text-sm mr-4"
            />

            <div className="w-full">
                {children}
            </div>
        </div>
    )
}

export default SettingsLayout