import { useState } from 'react';
import AddVideoLinkModal from './modals/AddVideoLinkModal';
import Button from 'components/buttons/Button';
import { FaPlus } from 'react-icons/fa6';
import axios from 'axios';
import { useNotificationsDispatch } from 'components/notifications/NotificationsContext';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import SortableVideo from 'views/admin/auctions/components/SortableVideo';

const VideoLinkGallery = ({ asset, assetType = 'lot' }) => {
  const notify = useNotificationsDispatch();
  const [videos, setVideos] = useState(asset.video_links);
  const [modalVisible, setModalVisible] = useState(false);

  const [form, setForm] = useState({
    id: null,
    name: '',
    url: '',
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const setFormData = (k, val) => {
    setForm({
      ...form,
      [k]: val,
    });
  };

  const editVideoLink = (video) => {
    setForm({
      id: video.id,
      name: video.name,
      url: video.meta.url,
    });
    setModalVisible(true);
  };

  const newVideoLink = () => {
    setForm({
      id: null,
      name: '',
      url: '',
    });
    setModalVisible(true);
  };

  const deleteVideoLink = (id) => {
    const url = `${process.env.REACT_APP_API_URL}/${assetType}/${asset.id}/video-links/${id}`;
    axios.delete(url, form).then(({ data }) => {
      setVideos(data[assetType].video_links);

      notify({
        message: 'Video link has been deleted',
        title: 'Success!',
        type: 'success',
      });
    });
  };

  const handleDragEnd = (e) => {
    const { active, over } = e;

    if (active.id !== over.id) {
      setVideos((videos) => {
        const oldIndex = videos.findIndex((asset) => asset.id === active.id);
        const newIndex = videos.findIndex((asset) => asset.id === over.id);

        const resorted = arrayMove(videos, oldIndex, newIndex);

        axios.post(
          process.env.REACT_APP_API_URL +
            `/${assetType}/${asset.id}/video-links/sort`,
          {
            order: resorted.map((asset) => asset.id),
          }
        );

        return resorted;
      });
    }
  };

  return (
    <div className="mb-2 rounded-lg border border-gray-200 px-4 py-4">
      <AddVideoLinkModal
        assetId={asset.id}
        setVideos={setVideos}
        assetType={assetType}
        visible={modalVisible}
        setVisible={setModalVisible}
        form={form}
        setFormData={setFormData}
      >
        <div className="flex justify-end">
          <Button size="md" onClick={newVideoLink}>
            <span className="flex items-center gap-2">
              <FaPlus className="h-4 w-4" />
              <span>Add Video Link</span>
            </span>
          </Button>
        </div>
      </AddVideoLinkModal>

      {videos && (
        <>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={videos}>
              <div className="mt-4 gap-6 sm:grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                {videos.map((video) => (
                  <SortableVideo
                    key={video.id}
                    asset={video}
                    onDelete={deleteVideoLink}
                    onEdit={editVideoLink}
                  />
                ))}
              </div>
            </SortableContext>
          </DndContext>
        </>
      )}
    </div>
  );
};

export default VideoLinkGallery;
