import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { can, useAuth } from 'components/auth/AuthContext'
import { index } from 'services/categories'
import SelectField from "components/fields/SelectField";
import { FilterMatchMode } from 'primereact/api'
import Btn from 'components/buttons/Button'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import SettingsLayout from '../settings/components/SettingsLayout'

import {
    FaMagnifyingGlass,
    FaPencil,
    FaPlus,
    FaEllipsisVertical
} from 'react-icons/fa6'

export default ({ }) => {
    const auth = useAuth()
    const canCreate = can(auth, 'create_categories')
    const canUpdate = can(auth, 'update_categories')

    const [categories, setCategories] = useState(null)

    const navigate = useNavigate()
    
    const types = [
        { label: 'All', value: '' },
        { label: 'Item', value: 'item' },
        { label: 'Auction', value: 'auction' },
    ]

    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ page, setPage ] = useState(1)
    const [ sort, setSort ] = useState({
        by: null,
        dir: null
    })
    const [ filters, setFilters ] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'categories.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'categories.type': { value: '', matchMode: FilterMatchMode.EQUALS },
    })

    const showBooleanValue = (rowData) => {
        return rowData.is_active ? "Yes" : "No";
    };


    useEffect(() => {
        index({
            page,
            by: sort.by,
            dir: sort.dir,
            filters: JSON.stringify(filters),
        }, ({ data }) => {
            setCategories(data.categories)
        })
    }, [page, sort, filters])


    const onPage = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            page: e.page + 1
        })
    }

    const onSort = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            by: e.sortField,
            dir: e.sortOrder
        })
    }

    const onFilter = (e) => {
        setFilters(e.filters)
    }

    const onCustomFilterChange = (e, name) => {
        const value = e.target.value;
        let _filters = { ... filters };

        _filters[name].value = value;

        onFilter({
            filters: _filters
        });
        console.log(filters);
    }

    const renderHeader = () => {
        return (
            <div className="flex gap-4 items-center">
                <div className="w-[160px]">
                    <SelectField
                        options={types}
                        value={filters['categories.type'].value}
                        onChange={(e) => {
                            return onCustomFilterChange(e, 'categories.type')
                        }}
                        placeholder="Filter by type"
                    />
                </div>
            </div>
        )
    }

    const header = renderHeader()
 
    return (
        <SettingsLayout expandedKeys={['auctions']}>
            {canCreate && (
                <div className="pt-3 mb-3 flex justify-end gap-4 items-center">
                    <Btn href="/admin/categories/create">
                        <span className="flex gap-2 items-center">
                            <FaPlus className="w-5 h-5" />
                            <span>New Category</span>
                        </span>
                    </Btn>
                </div>
            )}

            {categories && (
                <DataTable className="text-sm" value={categories.data} lazy dataKey="id" paginator
                        filters={filters} filterDisplay="menu" onFilter={onFilter} globalFilterFields={['categories.name']}
                        first={categories.from - 1} rows={categories.per_page} totalRecords={categories.total} onPage={onPage}
                        onSort={onSort} sortField={sort.by} sortOrder={sort.dir}
                        rowClassName="cursor-pointer"
                        onRowClick={(e) => {
                            navigate(canUpdate ? `/admin/categories/${e.data.id}/edit` : `/admin/user/${e.data.id}`)
                        }}
                        header={header}>
                    <Column field="name" sortField="categories.name" filterField="categories.name" header="Name" filter sortable />
                    <Column field="type" header="Type" filterField="categories.name" />
                    <Column field="slug" header="Slug" />
                    <Column field="parent.name" header="Parent" />
                    <Column field="is_active" header="Is Active" body={showBooleanValue} />
                    <Column field="children_visible_front" header="Children Visible Front" body={showBooleanValue} />
                    <Column field="tax_exempt" header="Tax Exempt" body={showBooleanValue} />
                    <Column field="disable_front_payments" header="Disable Front Payments" body={showBooleanValue} />
                </DataTable>
            )}
        </SettingsLayout>
    )
}