import { useRef, useState } from 'react'
import Card from "components/card";
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";
import Button from 'components/buttons/Button'
import { can, useAuth } from 'components/auth/AuthContext'

const Information = ({ company, setCompany, clients, companies, salespersonList, types, submit }) => {
    const [ errors, setErrors ] = useState({})

    const form = useRef()

    const auth = useAuth()
    const canAssignSalesperson = can(auth, 'assign_salesperson')

    const setData = (k, v) => {
        setCompany({
            ...company,
            [k]: v
        })
    }

    const validateAndSubmit = (e) => {
        e.preventDefault();

        if (form.current.reportValidity()) {
            submit(() => {
                setErrors({})
            }, ({ data }) => {
                if (data.errors) {
                    setErrors(data.errors)
                }
            });
        }
    }

    return (
        <Card extra={"w-full !p-5"}>
            {/* Header */}
            <div className="w-full px-[8px]">
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    Company Information
                </h4>
                <p className="mt-1 text-base text-gray-600">
                    Here you can change the company's information
                </p>
            </div>

            <form ref={form}>
                <div className="mt-7 mb-2 grid grid-cols-2 gap-3">
                    <InputField
                        label="Name"
                        id="name"
                        type="text"
                        value={company.name || ''}
                        onChange={(e) => setData('name', e.target.value)}
                        state={errors.name ? 'error' : ''}
                        above={errors.name ? (
                            <p className="text-red-500 text-xs mt-0.5">
                                {errors.name[0]}
                            </p>
                        ) : ''}
                    />

                    <InputField
                        label="Consignor Code"
                        id="consignor_code"
                        type="text"
                        value={company.consignor_code || ''}
                        onChange={(e) => setData('consignor_code', e.target.value)}
                        state={errors.consignor_code ? 'error' : ''}
                        above={errors.consignor_code ? (
                            <p className="text-red-500 text-2xs mt-0.5">
                                {errors.consignor_code[0]}
                            </p>
                        ) : ''}
                    />

                    <InputField
                        label="License Number (if applicable)"
                        id="license_number"
                        type="text"
                        value={company.license_number || ''}
                        onChange={(e) => setData('license_number', e.target.value)}
                    />

                    <SelectField
                        extra="mb-3"
                        label="Type"
                        id="type_id"
                        value={company.type_id || ''}
                        onChange={(e) => setData('type_id', e.target.value)}
                        options={types.map(type => {
                            return {
                                label: type.name,
                                value: type.id
                            }
                        })}
                    />

                    <SelectField
                        extra="mb-3"
                        label="Client"
                        id="client_id"
                        value={company.client_id || ''}
                        onChange={(e) => setData('client_id', e.target.value)}
                        options={clients.map(client => {
                            return {
                                label: client.name,
                                value: client.id
                            }
                        })}
                    />

                    <SelectField
                        extra="mb-3"
                        label="Parent Company"
                        id="parent_id"
                        value={company.parent_id || ''}
                        onChange={(e) => setData('parent_id', e.target.value)}
                        options={companies.map(company => {
                            return {
                                label: company.name,
                                value: company.id
                            }
                        })}
                    />

                    {canAssignSalesperson && (
                        <SelectField
                            extra="mb-3"
                            label="Salesperson"
                            id="salesperson_id"
                            value={company.salesperson_id || ''}
                            onChange={(e) => setData('salesperson_id', e.target.value)}
                            options={salespersonList.map(salesperson => {
                                return {
                                    label: salesperson.name,
                                    value: salesperson.id
                                }
                            })}
                        />
                    )}
                </div>

                <div className="mt-12 flex w-full justify-end">
                    <Button
                        onClick={validateAndSubmit}
                    >Submit</Button>
                </div>
            </form>
        </Card>
    );
};

export default Information;
