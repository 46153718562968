import { useParams } from 'react-router-dom';
import { get } from 'services/auctions';
import { can, useAuth } from 'components/auth/AuthContext';
import { useEffect, useState } from 'react';
import AuctionLayout from 'views/admin/auctions/components/AuctionLayout';
import Button from 'components/buttons/Button';
import { FaFileExcel, FaFilePdf } from 'react-icons/fa6';
import { IoOptions } from 'react-icons/io5';
import ReportOptionsModal from './components/modals/ReportOptionsModal';
import { FaHistory } from 'react-icons/fa';
import ReportHistoryModal from './components/modals/ReportHistoryModal';

const Reports = () => {
  const { id } = useParams();
  const [auction, setAuction] = useState(null);
  const [visible, setVisible] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [activeReport, setActiveReport] = useState(false);

  const auth = useAuth();
  const canViewAllReports = can(auth, 'view_auctions_reports_all');

  useEffect(() => {
    get(id, ({ data }) => {
      setAuction(data.auction);
    });
  }, []);

  const showReportHistory = (report) => {
    setSelectedReport(report);
    setVisible(true);
  };

  const showOptions = (report) => {
    setActiveReport(report);
    setShowOptionsModal(true);
  };

  const showFormat = (report, format) => {
    return report.formats.indexOf(format) >= 0;
  };

  const showFormatButton = (report, format, options) => {
    if (! report) {
      return;
    }

    const invisible = ! showFormat(report, format)

    let href = `${process.env.REACT_APP_BASE_URL}/admin/report/${auction.id}?report=${report.type}&format=${format}`;

    if (options) {
      const params = new URLSearchParams(options);
      href += '&' + params.toString();
    }

    if (format === 'excel') {
      return (
        <Button target="_blank" href={href} color="outline" size="md" invisible={invisible}>
          <FaFileExcel className="h-5 w-5 text-green-600" /> Excel
        </Button>
      );
    } else if (format === 'pdf') {
      return (
        <Button target="_blank" href={href} color="outline" size="md" invisible={invisible}>
          <FaFilePdf className="h-5 w-5 text-red-600" /> PDF
        </Button>
      );
    }
  };

  const reports = [
    canViewAllReports
      ? {
          name: 'Auction Summary',
          type: 'auction-summary',
          formats: ['pdf', 'excel'],
        }
      : null,
    {
      name: 'Auction Catalog',
      type: 'auction-catalog',
      formats: ['pdf'],
    },
    {
      name: 'Auctioneer Report',
      type: 'auctioneer-report',
      formats: ['pdf'],
    },
    {
      name: 'Lot Summary',
      type: 'lot-summary',
      formats: ['pdf', 'excel'],
    },
    canViewAllReports
      ? {
          name: 'Buyer Summary',
          type: 'buyer-summary',
          formats: ['pdf', 'excel'],
        }
      : null,
    canViewAllReports
      ? {
          name: 'Collections Report',
          type: 'collections',
          formats: ['pdf', 'excel']
        }
      : null,
    {
      name: 'Consignor Summary',
      type: 'consignor-summary',
      formats: ['pdf', 'excel'],
    },
    canViewAllReports
      ? {
          name: 'Bidder Report',
          type: 'bidder-report',
          formats: ['pdf', 'excel'],
        }
      : null,
    {
      name: 'Invoice Payments Report',
      type: 'invoice-payments-report',
      formats: ['pdf', 'excel'],
    },
    {
      name: 'Auction Lot Sales Report',
      type: 'auction-lot-sales-report',
      formats: ['pdf', 'excel'],
      options: [
        canViewAllReports
          ? {
              label: 'Premium Included in Price',
              name: 'premium_included',
            }
          : null,
      ],
    },
  ].filter((report) => report !== null);

  if (!auction) {
    return;
  }

  return (
    <AuctionLayout id={id} auction={auction} setAuction={setAuction}>
      <div>
        {reports.map((report) => {
          return (
            <div className="my-4 flex items-center gap-2" key={report.type}>
              <div className="w-60">{report.name}</div>
              <div>{showFormatButton(report, 'pdf')}</div>
              <div>{showFormatButton(report, 'excel')}</div>
              {canViewAllReports && (
                <div>
                  <Button
                    onClick={() => showOptions(report)}
                    invisible={! report.options}
                    color="outline"
                    size="md"
                  >
                    <IoOptions className="h-5 w-5" /> Options
                  </Button>
                </div>
              )}
              <div>
                <Button
                  onClick={(e) => showReportHistory(report)}
                  type="button"
                  color="outline"
                  size="md"
                >
                  <FaHistory className="h-5 w-5 text-gray-600" /> History
                </Button>
              </div>
            </div>
          );
        })}
      </div>

      <ReportOptionsModal
        auction={auction}
        report={activeReport}
        visible={showOptionsModal}
        setVisible={setShowOptionsModal}
        showFormatButton={showFormatButton}
      />

      {selectedReport && (
        <ReportHistoryModal
          visible={visible}
          setVisible={setVisible}
          report={selectedReport}
          auction={auction}
        />
      )}
    </AuctionLayout>
  );
};

export default Reports;
