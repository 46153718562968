import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import InputField from "components/fields/InputField";
import SelectField from "components/fields/SelectField";
import TextField from "components/fields/TextField";
import SwitchField from 'components/fields/SwitchField';
import Button from 'components/buttons/Button'
import Card from "components/card";
import { getPrimaryRoles } from 'services/users'
import { get, create, update, getParents } from 'services/categories'
import SettingsLayout from '../settings/components/SettingsLayout'

const EditCategory = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [category, setCategory] = useState({})
    const [roles, setRoles] = useState([])
    const [parents, setParents] = useState([])
    
    const types = [
        { label: 'Item', value: 'item' },
        { label: 'Auction', value: 'auction' },
    ]

    useEffect(() => {
        getPrimaryRoles({}, ({ data }) => {
            setRoles(data.roles)
        })
    }, [])

    useEffect(() => {
        if (id) {
            get(id, ({ data }) => {
                setCategory(data.category)
            })
            getParents(id, ({ data }) => {
                setParents(data.categories)
            })
        } else {
            getParents(null, ({ data }) => {
                setParents(data.categories)
            })
        }
    }, [])

    const setData = (k, v) => {
        setCategory({
            ...category,
            [k]: v
        })
    }

    const submit = () => {
        if (id) {
            update(id, category, ({ data }) => {
                navigate('/admin/categories')
            })
        } else {
            create(category, ({ data }) => {
                navigate('/admin/categories')
            })
        }
    }
    
    return (
        <SettingsLayout expandedKeys={['auctions']}>
            <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 rounded-[20px] lg:grid-cols-2">
                <div className="flex flex-col gap-5">

                    <Card extra={"w-full !p-5"}>
                        <div className="w-full px-[8px]">
                            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                                Category Information
                            </h4>
                            <p className="mt-1 text-base text-gray-600">
                                Here you can change the category's information
                            </p>
                        </div>
                        <div className="mt-7 mb-2 grid grid-cols-2 gap-3">
                            <SelectField
                                label="Parent Category"
                                id="parent_id"
                                value={category.parent_id || ''}
                                options={parents}
                                placeholder="Select a parent category"
                                onChange={(e) => setData('parent_id', e.target.value)}
                            />
                            <SelectField
                                label="Type"
                                id="type"
                                value={category.type || ''}
                                options={types}
                                placeholder="Select a type"
                                onChange={(e) => setData('type', e.target.value)}
                            />
                            <InputField
                                label="Name"
                                id="name"
                                type="text"
                                value={category.name || ''}
                                onChange={(e) => setData('name', e.target.value)}
                            />
                            <InputField
                                label="Slug"
                                id="slug"
                                type="text"
                                value={category.slug || ''}
                                onChange={(e) => setData('slug', e.target.value)}
                            />
                            <div className="col-span-2">
                                <TextField
                                    label="Description"
                                    id="description"
                                    value={category.description || ''}
                                    onChange={(e) => setData('description', e.target.value)}
                                    />
                            </div>
                            <div className="col-span-1 space-y-2">
                                <label className="flex gap-2 items-center">
                                    <input
                                        type="checkbox"
                                        checked={category.is_active || false}
                                        onChange={(e) => setData('is_active', e.target.checked)}
                                    />
                                    <span>Is Active</span>
                                </label>
                                <label className="flex gap-2 items-center">
                                    <input
                                        type="checkbox"
                                        checked={category.children_visible_front || false}
                                        onChange={(e) => setData('children_visible_front', e.target.checked)}
                                    />
                                    <span>Children visible on front</span>
                                </label>
                                <label className="flex gap-2 items-center">
                                    <input
                                        type="checkbox"
                                        checked={category.tax_exempt || false}
                                        onChange={(e) => setData('tax_exempt', e.target.checked)}
                                    />
                                    <span>Tax Exempt</span>
                                </label>
                                <label className="flex gap-2 items-center">
                                    <input
                                        type="checkbox"
                                        checked={category.disable_front_payments || false}
                                        onChange={(e) => setData('disable_front_payments', e.target.checked)}
                                    />
                                    <span>Disable front payments</span>
                                </label>
                            </div>
                        </div>

                        <div className="mt-12 flex w-full justify-end">
                            <Button
                                onClick={submit}
                            >Submit</Button>
                        </div>
                    </Card>
                </div>
            </div>
        </SettingsLayout>
    )
}

export default EditCategory;