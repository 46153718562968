import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { can, useAuth } from 'components/auth/AuthContext'
import { index, getStatuses, update } from 'services/auctions'
import { all as getClients } from 'services/clients'
import { all as getCompanies } from 'services/companies'
import Btn from 'components/buttons/Button'
import SplitButton from 'components/buttons/SplitButton'
import SelectField from 'components/fields/SelectField'
import { FilterMatchMode } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Menu } from 'primereact/menu'
import { debounce } from 'lodash'

import {
    CgTemplate
} from 'react-icons/cg'

import {
    FaCircleCheck,
    FaClone,
    FaGlobe,
    FaMagnifyingGlass,
    FaPencil,
    FaPlus,
    FaEllipsisVertical,
    FaRegStar,
    FaTags,
    FaStar,
    FaUserCheck
} from 'react-icons/fa6'

import {
    MdGavel
} from 'react-icons/md'

export default ({ client, company, templates = false, pending = false }) => {
    const auth = useAuth()
    const canCreate = can(auth, 'create_auctions')
    const canCreateTemplates = can(auth, 'create_templates')
    const canUpdateActive = can(auth, 'update_active_auctions')
    const canViewClients = can(auth, 'view_clients')
    const canExport = can(auth, 'export_auctions')

    const prefiltered = !!(client || company)
    const navigate = useNavigate()
    const [ auctions, setAuctions ] = useState([])
    const [ clients, setClients ] = useState([])
    const [ companies, setCompanies ] = useState([])
    const [ statuses, setStatuses ] = useState([])
    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ page, setPage ] = useState(1)
    const [ sort, setSort ] = useState({
        by: null,
        dir: null
    })
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'auctions.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'auctions.description': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'company.consignor_code': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'location.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'auctions.starts_at': { value: null, matchMode: FilterMatchMode.DATE_AFTER },
        'auctions.ends_at': { value: null, matchMode: FilterMatchMode.DATE_BEFORE },
        'auctions.created_at': { value: null, matchMode: 'dateBetween' },
        'auctions.company_id': { value: company?.id, matchMode: FilterMatchMode.EQUALS },
        'client_id': { value: '', matchMode: FilterMatchMode.EQUALS },
        'auctions.status': { value: pending ? 'pending' : '', matchMode: FilterMatchMode.EQUALS },
        'show_archived': false,
        'show_deleted': false
    })
    const [ globalFilterValue, setGlobalFilterValue ] = useState('')
    const [ showArchived, setShowArchived ] = useState(false)
    const [ showDeleted, setShowDeleted ] = useState(false)

    useEffect(() => {
        setPage(
            Number(searchParams.get('page')) || 1
        )

        setSort({
            by: searchParams.get('by') || 'auctions.ends_at',
            dir: searchParams.get('dir') || 1
        })

        window.scrollTo(0, 0)
    }, [searchParams])

    useEffect(() => {
        index({
            page,
            by: sort.by,
            dir: sort.dir,
            filters: JSON.stringify(filters),
            is_template: templates ? 1 : 0
        }, ({ data }) => {
            setAuctions(data.auctions)
        })
    }, [page, sort, filters])

    useEffect(() => {
        if (! prefiltered) {
            getClients({}, ({ data }) => {
                setClients(data.clients)
            })

            getCompanies({}, ({ data }) => {
                setCompanies(data.companies)
            })
        }

        getStatuses({}, ({ data }) => {
            setStatuses(data.statuses)
        })
    }, [])

    const onPage = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            page: e.page + 1
        })
    }

    const onSort = (e) => {
        setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            by: e.sortField,
            dir: e.sortOrder
        })
    }

    const onFilter = (e) => {
        setFilters(e.filters)
    }

    const debouncedFilter = useMemo(
        () => debounce(e => {
            onFilter(e)
        }, 500),
        []
    )

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        debouncedFilter({
            filters: _filters
        });
        setGlobalFilterValue(value);
    }

    const onCustomFilterChange = (e, name) => {
        const value = e.target.value;
        let _filters = { ... filters };

        _filters[name].value = value;

        onFilter({
            filters: _filters
        });
    }

    const loading = false

    const renderHeader = () => {
        return (
            <div className="flex gap-4 items-center">
                <span className="p-icon-field p-icon-field-left">
                    <FaMagnifyingGlass className="w-5 h-5 p-input-icon" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>

                {! prefiltered && (
                    <>
                        {canViewClients && (
                            <div>
                                <SelectField
                                    options={clients.map(client => {
                                        return {
                                            label: client.name,
                                            value: client.id
                                        }
                                    })}
                                    value={filters.client_id.value}
                                    onChange={(e) => {
                                        return onCustomFilterChange(e, 'client_id')
                                    }}
                                    placeholder="Filter by client"
                                />
                            </div>
                        )}

                        <div>
                            <SelectField
                                options={companies.map(company => {
                                    return {
                                        label: company.name,
                                        value: company.id
                                    }
                                })}
                                value={filters['auctions.company_id'].value}
                                onChange={(e) => {
                                    return onCustomFilterChange(e, 'auctions.company_id')
                                }}
                                placeholder="Filter by company"
                            />
                        </div>
                    </>
                )}

                {! templates && (
                    <div className="w-[160px]">
                        <SelectField
                            options={statuses}
                            value={filters['auctions.status'].value}
                            onChange={(e) => {
                                return onCustomFilterChange(e, 'auctions.status')
                            }}
                            placeholder="Filter by status"
                            disabled={pending}
                        />
                    </div>
                )}

                {! templates && (
                    <div>
                        <label className="flex gap-2 items-center">
                            <input
                                type="checkbox"
                                value={showArchived}
                                onChange={(e) => {
                                    setShowArchived(e.target.checked)

                                    let _filters = { ...filters }

                                    _filters['show_archived'] = e.target.checked

                                    onFilter({
                                        filters: _filters
                                    });
                                }}
                            />
                            <span>Show archived</span>
                        </label>
                    </div>
                )}

                <div>
                    <label className="flex gap-2 items-center">
                        <input
                            type="checkbox"
                            value={showDeleted}
                            onChange={(e) => {
                                setShowDeleted(e.target.checked)

                                let _filters = { ...filters }

                                _filters['show_deleted'] = e.target.checked

                                onFilter({
                                    filters: _filters
                                });
                            }}
                        />
                        <span>Show deleted</span>
                    </label>
                </div>
            </div>
        )
    }

    const header = renderHeader()

    const MenuBodyTemplate = (rowData) => {
        const menu = useRef(null);
        const items = rowData.editable ? [
            {
                label: 'Edit Auction',
                icon: <FaPencil className="w-5 h-5 pr-2" />,
                command: () => {
                    navigate(`/admin/auction/${rowData.id}`)
                }
            },
            {
                label: 'Copy Auction',
                icon: <FaClone className="w-5 h-5 pr-2" />,
                command: () => {
                    navigate(`/admin/auction/${rowData.id}/clone`)
                }
            }
        ] : [{
                label: 'View Auction',
                icon: <MdGavel className="w-5 h-5 pr-2" />,
                command: () => {
                    navigate(`/admin/auction/${rowData.id}`)
                }
        }]

        return (
            <>
                <Menu model={items} popup ref={menu} id={"popup_menu_" + rowData.id} popupAlignment="left" />
                <Button text icon={(
                    <FaEllipsisVertical className="w-5 h-5" />
                )} className="mr-2" onClick={(event) => menu.current.toggle(event)} aria-controls={"popup_menu_" + rowData.id} aria-haspopup />
            </>
        )
    }

    const FeatureTemplate = (rowData) => {
        return (
            <div className="cursor-pointer px-2" onClick={(e) => updateFeatured(e, rowData.id, ! rowData.is_featured)}>
                {rowData.is_featured ? (
                    <FaStar
                        className="w-5 h-5"
                        title="Currently featured. Click to toggle off."
                    />
                ) : (
                    <FaRegStar
                        className="w-5 h-5"
                        title="Not currently featured. Click to toggle on."
                    />
                )}
            </div>
        )
    }

    const updateFeatured = (e, id, is_featured) => {
        e.preventDefault()

        update(id, {
            is_featured
        }, ({ data }) => {
            setAuctions({
                ...auctions,
                data: auctions.data.map(auction => {
                    if (auction.id === id) {
                        return {
                            ...auction,
                            is_featured
                        }
                    }

                    return auction
                })
            })
        })
    }

    const startsAtFilterTemplate = (options) => {
        const date = options.value;

        return (
            <input value={date || ''}
                   onChange={(e) => options.filterApplyCallback(e.target.value)}
                   type={templates ? 'time' : 'date'}
                   className="w-full p-inputtext p-component p-column-filter"
                   placeholder="Starts after"
            />
        );
    }

    const endsAtFilterTemplate = (options) => {
        const date = options.value;

        return (
            <input value={date || ''}
                   onChange={(e) => options.filterApplyCallback(e.target.value)}
                   type={templates ? 'time' : 'date'}
                   className="w-full p-inputtext p-component p-column-filter"
                   placeholder="Ends before"
            />
        );
    }

    const createdAtFilterTemplate = (options) => {
        const [from, to] = options.value ?? ['', ''];

        return (
            <div className="flex gap-1">
                <input value={from}
                       onChange={(e) => options.filterApplyCallback([e.target.value, to])}
                       type="date"
                       className="w-full p-inputtext p-component p-column-filter"
                       placeholder="From"
                />
                <input value={to}
                       onChange={(e) => options.filterApplyCallback([from, e.target.value])}
                       type="date"
                       className="w-full p-inputtext p-component p-column-filter"
                       placeholder="From"
                />
            </div>
        );
    }

    return (
        <div className="mt-3">
            {canCreate && (
                <div className="pt-3 mb-3 flex justify-end gap-4 items-center">
                    {templates ? (
                        <Btn href="/admin/auctions/create/template">
                            <span className="flex gap-2 items-center">
                                <FaPlus className="w-5 h-5" />
                                <span>New Template</span>
                            </span>
                        </Btn>
                    ) : (
                        <>
                            {canUpdateActive && (
                                <>
                                    {pending ? (
                                        <Btn color="outline" size="md" href="/admin/auctions">
                                            <span className="flex gap-2 items-center">
                                                <MdGavel className="w-5 h-5" />
                                                <span>All Auctions</span>
                                            </span>
                                        </Btn>
                                    ) : (
                                        <Btn color="outline" size="md" href="/admin/auctions/pending">
                                            <span className="flex gap-2 items-center">
                                                <FaCircleCheck className="w-5 h-5" />
                                                <span>Auction Approvals</span>
                                            </span>
                                        </Btn>
                                    )}
                                    <Btn color="outline" size="md" href="/admin/auctions/lot/approvals">
                                        <span className="flex gap-2 items-center">
                                            <FaTags className="w-5 h-5" />
                                            <span>Lot Approvals</span>
                                        </span>
                                    </Btn>
                                    <Btn color="outline" size="md" href="/admin/auctions/approvals">
                                        <span className="flex gap-2 items-center">
                                            <FaUserCheck className="w-5 h-5" />
                                            <span>Registration Approvals</span>
                                        </span>
                                    </Btn>
                                </>
                            )}

                            {canCreateTemplates && (
                                <Btn color="outline" size="md" href="/admin/auctions/templates">
                                    <span className="flex gap-2 items-center">
                                        <CgTemplate className="w-5 h-5" />
                                        <span>Templates</span>
                                    </span>
                                </Btn>
                            )}

                            {canExport ? (
                                <SplitButton
                                    items={[
                                        { name: 'Export Auctions', onClick: () => {
                                            const params = new URLSearchParams({
                                                by: sort.by || 'id',
                                                dir: sort.dir,
                                                filters: JSON.stringify({
                                                    ...filters,
                                                    // ...additionalFilters
                                                })
                                            })
                                            const href = `${process.env.REACT_APP_BASE_URL}/admin/auctions/export?` + params.toString()
                                            window.open(href, '_blank')
                                        } }
                                    ]}
                                    url="/admin/auctions/create"
                                >
                                    <span className="flex gap-2 items-center">
                                        <FaPlus className="w-5 h-5" />
                                        <span>New Auction</span>
                                    </span>
                                </SplitButton>
                            ) : (
                                <Btn href="/admin/auctions/create">
                                    <span className="flex gap-2 items-center">
                                        <FaPlus className="w-5 h-5" />
                                        <span>New Auction</span>
                                    </span>
                                </Btn>
                            )}
                        </>
                    )}
                </div>
            )}

            {auctions && (
                <DataTable className="text-sm" value={auctions.data} lazy dataKey="id" paginator
                           filters={filters} filterDisplay="menu" onFilter={debouncedFilter} globalFilterFields={['auctions.name', 'auctions.description', 'company.consignor_code']}
                           first={auctions.from - 1} rows={auctions.per_page} totalRecords={auctions.total} onPage={onPage}
                           onSort={onSort} sortField={sort.by} sortOrder={sort.dir}
                           rowClassName={(data) => {
                               if (data.deleted_at) {
                                   return "cursor-pointer bg-red-50 text-red-700"
                               }

                               return "cursor-pointer"
                           }}
                           onRowClick={(e) => {
                               navigate(`/admin/auction/${e.data.id}`)
                           }}
                           header={header} loading={loading} tableStyle={{ minWidth: '75rem' }}>
                    <Column headerStyle={{ width: '4rem' }} body={MenuBodyTemplate}></Column>
                    <Column headerStyle={{ width: '4rem' }} body={FeatureTemplate}></Column>
                    <Column field="name" sortField="auctions.name" filterField="auctions.name" header="Name" filter sortable bodyClassName="font-bold" style={{ minWidth: '300px' }} />
                    <Column field="description" sortField="auctions.description" filterField="auctions.description" filter sortable header="Description" style={{ minWidth: '300px' }} body={(auction) => (
                        <p className="line-clamp-1" title={auction.description}>
                            {auction.description}
                        </p>
                    )} />
                    <Column field="company.consignor_code" filter sortable header="C. Code" headerClassName="whitespace-nowrap" style={{ minWidth: '180px' }}
                            body={(auction) => {
                                return (
                                    auction.company ?
                                        <>
                                            {auction.company.consignor_code}
                                        </> : (
                                            auction.is_global && (
                                                <FaGlobe className="w-5 h-5" title="Global Template" />
                                            )
                                        )
                                )
                            }}
                    />
                    <Column field="location.name" filter sortable header="Location" style={{ minWidth: '220px' }}  />
                    <Column field="dates.starts" sortField="auctions.starts_at" filterField="auctions.starts_at" filter showFilterMenu={false} sortable header="Start"
                            filterElement={startsAtFilterTemplate}
                            body={(auction) => {
                                return (
                                    <>
                                        {templates ? auction.dates.starts_time : auction.dates.starts}
                                    </>
                                )
                            }}
                    />
                    <Column field="dates.ends" sortField="auctions.ends_at" filterField="auctions.ends_at" filter showFilterMenu={false} sortable header="End"
                            filterElement={endsAtFilterTemplate}
                            body={(auction) => {
                                return (
                                    <>
                                        {templates ? auction.dates.ends_time : auction.dates.ends}
                                    </>
                                )
                            }}
                    />
                    <Column field="dates.created" sortField="auctions.created_at" filterField="auctions.created_at" filter showFilterMenu={false} sortable header="Created" filterElement={createdAtFilterTemplate} />

                    {! templates && (
                        <Column field="status" header="Status" />
                    )}

                    {! templates && (
                        <Column field="counts.lots" header="Lots" />
                    )}
                </DataTable>
            )}
        </div>
    )
}