import { Dialog } from 'primereact/dialog';
import Button from 'components/buttons/Button';
import { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FaDownload } from 'react-icons/fa6';
import { loadReportHistory } from 'services/reports';

const ReportHistoryModal = ({ auction, report, visible, setVisible }) => {
  const header = <div className="text-lg font-bold">{report.name}</div>;
  const [reports, setReports] = useState(null);

  useEffect(() => {
    loadReportHistory(
      {
        auctionId: auction.id,
        type: report.type,
      },
      ({ data }) => {
        setReports(data.reportHistory);
      }
    );
  }, [auction, report]);

  const buildHref = (rowData) => {
    return `${process.env.REACT_APP_BASE_URL}/admin/report/auctions/${auction.id}/report-history/${rowData.id}/download`;
  };

  const footer = (
    <div>
      <Button color="outline" onClick={() => setVisible(false)}>
        Close
      </Button>
    </div>
  );

  return (
    <Dialog
      modal
      draggable={false}
      resizable={false}
      header={header}
      footer={footer}
      style={{ width: '70rem' }}
      onHide={() => setVisible(false)}
      visible={visible}
    >
      {reports && (
        <DataTable
          className="text-sm"
          value={reports.data}
          lazy
          dataKey="id"
          paginator
          first={reports.from - 1}
          emptyMessage="No results"
          rows={reports.per_page}
          totalRecords={reports.total}
        >
          <Column field="format" header="Format" />
          <Column field="dates.created" header="Created At" />
          <Column
            body={(rowData) => (
              <>
                <Button
                  target="_blank"
                  href={buildHref(rowData)}
                  color="outline"
                  size="md"
                >
                  <FaDownload className="h-5 w-5" />{' '}
                  {rowData.format}
                </Button>
              </>
            )}
          ></Column>
        </DataTable>
      )}
    </Dialog>
  );
};

export default ReportHistoryModal;
