import InputField from 'components/fields/InputField'
import SelectField from 'components/fields/SelectField'
import { formatDatetime, formatTime } from 'helpers/dates'
import TextField from 'components/fields/TextField'
import { useEffect, useRef, useState } from 'react'
import { all as allAuctions, get as getAuction, getStatuses } from 'services/auctions'
import { all as allCompanies } from 'services/companies'
import { states } from 'services/states'
import { timezones } from 'services/timezones'
import { getByType } from 'services/categories'
import { can, useAuth } from 'components/auth/AuthContext'
import AddLocationModal from '../../companies/components/modals/AddLocationModal'
import EditLocationModal from '../../companies/components/modals/EditLocationModal'
import Label from 'components/fields/Label'
import ReactQuill from 'react-quill'

const AuctionForm = ({ auction, company, setAuction, setCompany, setData }) => {
    const auth = useAuth()
    const canUpdateActive = can(auth, 'update_active_auctions')
    const canUpdateUnrestricted = can(auth, 'update_auctions_unrestricted')

    const previousCompany = useRef(null)
    const [ companies, setCompanies ] = useState([])
    const [ templates, setTemplates ] = useState([])
    const [ template, setTemplate ] = useState(null)
    const [ companiesOptions, setCompaniesOptions ] = useState([])
    const [ locationsOptions, setLocationsOptions ] = useState([])
    const [ templatesOptions, setTemplatesOptions ] = useState([])
    const [ statusOptions, setStatusOptions ] = useState([])
    const [ categories, setCategories ] = useState([])
    const [ location, setLocation ] = useState(null)

    useEffect(() => {
        allCompanies({}, ({ data }) => {
            setCompanies(data.companies)
        })
        getStatuses({}, ({ data }) => {
            setStatusOptions(data.statuses)
        })

        if (! auction.id) {
            allAuctions({
                is_template: true
            }, ({ data }) => {
                setTemplates(data.auctions)
            })
        }
    }, [])

    useEffect(() => {
        setCompaniesOptions(
            companies.map(company => {
                return {
                    label: company.name,
                    value: company.id
                }
            })
        )
    }, [companies])

    useEffect(() => {
        if (auction.name === '' || (previousCompany.current && previousCompany.current.name === auction.name)) {
            setData('name', company?.name || '')
        }

        previousCompany.current = company

        if (! company) {
            setLocationsOptions([])
            return
        }

        setLocationsOptions(
            company.locations.map(location => {
                return {
                    label: location.name || location.full_address,
                    value: location.id
                }
            })
        )
        setLocationFromId(auction.location_id)
    }, [company])

    useEffect(() => {
        setTemplatesOptions(
            templates.map(template => {
                return {
                    label: template.name,
                    value: template.id
                }
            })
        )
    }, [templates])

    useEffect(() => {
        if (template) {
            setAuction({
                ...template,
                id: null,
                is_template: auction.is_template,
                is_global: false,
                template_id: template.id
            })
            setCompanyFromId(template.company_id)
        }
    }, [template])

    useEffect(() => {
        getByType('auction', ({ data }) => {
            setCategories(data.categories)
        })
    }, [])

    const selectCompany = (v) => {
        setData('company_id', v)

        setCompanyFromId(v)
    }

    const setCompanyFromId = (v) => {
        setCompany(
            companies.filter(company => company.id == v)[0]
        )
    }

    const selectTemplate = (v) => {
        if (v) {
            getAuction(v, ({ data }) => {
                setTemplate(data.auction)
            })
        } else {
            setTemplate(null)
            setData('template_id', null)
        }
    }

    const selectState = (v) => {
        setData('state_code', v)
    }

    const selectTimezone = (v) => {
        setData('timezone', v)
    }

    const setConfig = (k, v) => {
        const config = auction.config || {}

        setAuction({
            ...auction,
            config: {
                ...config,
                [k]: v
            }
        })
    }

    const toggleCheckbox = (k) => {
        setConfig(k, ! (auction.config ? auction.config[k] : false))
    }

    const setLocationFromId = (id) => {
        setLocation(
            id
                ? company.locations.filter(l => l.id == id)[0]
                : null
        )
    }
    
    return (
        <>
            {! auction.id && ! auction.clone && (
                <SelectField
                    extra="mb-3"
                    label="Template"
                    id="template_id"
                    value={auction.template_id || ''}
                    onChange={(e) => selectTemplate(e.target.value)}
                    options={templatesOptions}
                />
            )}

            {auction.clone && (
                <div className="pl-2">
                    <p className="mb-2">
                        All auction settings will be copied over. Invoices &amp; statements will not be copied.
                    </p>
                    <p className="mb-2">
                        Lots will not be copied unless you check the following box.
                    </p>

                    <label
                        className="flex gap-2 items-center mb-4"
                        title="Copy all lots to new auction. This will also increase the quantity in inventory. No invoices and statements will be copied."
                    >
                        <input
                            type="checkbox"
                            onChange={(e) => setData('clone_lots', e.target.checked)}
                            checked={auction.clone_lots}
                        />
                        <span>Copy All Lots</span>
                    </label>
                </div>
            )}

            {(canUpdateUnrestricted || auction.id || auction.template_id) && (
                <>
                    <SelectField
                        extra="mb-3"
                        label="Company"
                        id="company_id"
                        value={auction.company_id || ''}
                        onChange={(e) => selectCompany(e.target.value)}
                        options={companiesOptions}
                    />

                    <InputField
                        extra="mb-3"
                        label="Name"
                        id="name"
                        value={auction.name || ''}
                        onChange={(e) => setData('name', e.target.value)}
                        type="text"
                    />

                    {canUpdateUnrestricted && (
                        <SelectField
                            extra="mb-3"
                            label="Category"
                            id="category_id"
                            value={auction.category_id || ''}
                            onChange={(e) => setData('category_id', e.target.value)}
                            options={categories}
                        />
                    )}

                    {canUpdateActive && (
                        <SelectField
                            extra="mb-3"
                            label="Status"
                            id="status"
                            value={auction.status || ''}
                            onChange={(e) => setData('status', e.target.value)}
                            options={statusOptions}
                        />
                    )}

                    <SelectField
                        extra="mb-3"
                        label="Location"
                        id="location_id"
                        value={auction.location_id || ''}
                        onChange={(e) => {
                            setData('location_id', e.target.value)
                            setLocationFromId(e.target.value)
                        }}
                        options={locationsOptions}
                    />

                    {company && (
                        <div className="flex items-center gap-4 mb-4">
                            <AddLocationModal
                                company={company}
                                after={(newLocation) => {
                                    setCompany({
                                        ...company,
                                        locations: [
                                            ...company.locations,
                                            newLocation
                                        ]
                                    })
                                    setData('location_id', newLocation.id)
                                }}
                            />

                            {location && (
                                <EditLocationModal
                                    location={location}
                                    after={(location) => {
                                        setCompany({
                                            ...company,
                                            locations: company.locations.map(l => {
                                                if (l.id == location.id) {
                                                    return location
                                                }

                                                return l
                                            })
                                        })
                                    }}
                                />
                            )}
                        </div>
                    )}


                    {(canUpdateUnrestricted || ! auction.location_id ) && (
                        <SelectField
                            extra="mb-3"
                            label="State"
                            id="state_code"
                            value={auction.state_code || ''}
                            onChange={(e) => selectState(e.target.value)}
                            options={states}
                        />
                    )}

                    {canUpdateUnrestricted && (
                        <SelectField
                            extra="mb-3"
                            label="Time Zone"
                            id="timezone"
                            value={auction.timezone || ''}
                            onChange={(e) => selectTimezone(e.target.value)}
                            options={timezones}
                        />
                    )}

                    <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                        <InputField
                            extra="mb-3"
                            label="Starts"
                            value={auction.is_template ? formatTime(auction.starts_at || '') : formatDatetime(auction.starts_at || '')}
                            onChange={(e) => setData('starts_at', e.target.value)}
                            id="starts_at"
                            type={auction.is_template ? "time" : "datetime-local"}
                            above={(
                                <div className="text-xs ml-2 mt-0.5">
                                    <label className="flex gap-1 items-center" title="Hide start time on site">
                                        <input type="checkbox"
                                               checked={auction.config?.hide_start_time || false}
                                               onChange={() => toggleCheckbox('hide_start_time')}
                                        />
                                        <span>Hide Start Time</span>
                                    </label>
                                </div>
                            )}
                        />
                        <InputField
                            extra="mb-3"
                            label="Ends"
                            value={auction.is_template ? formatTime(auction.ends_at || '') : formatDatetime(auction.ends_at || '')}
                            onChange={(e) => setData('ends_at', e.target.value)}
                            placeholder=""
                            id="ends_at"
                            type={auction.is_template ? "time" : "datetime-local"}
                            above={(
                                <div className="text-xs ml-2 mt-0.5">
                                    <label className="flex gap-1 items-center" title="Hide end time on site">
                                        <input type="checkbox"
                                               checked={auction.config?.hide_end_time || false}
                                               onChange={() => toggleCheckbox('hide_end_time')}
                                        />
                                        <span>Hide End Time</span>
                                    </label>
                                </div>
                            )}
                        />
                    </div>

                    <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                        <InputField
                            extra="mb-3"
                            label="Preview Starts"
                            value={auction.is_template ? formatTime(auction.preview_starts_at || '') : formatDatetime(auction.preview_starts_at || '')}
                            onChange={(e) => setData('preview_starts_at', e.target.value)}
                            id="preview_starts_at"
                            type={auction.is_template ? "time" : "datetime-local"}
                        />
                        <InputField
                            extra="mb-3"
                            label="Preview Ends"
                            value={auction.is_template ? formatTime(auction.preview_ends_at || '') : formatDatetime(auction.preview_ends_at || '')}
                            onChange={(e) => setData('preview_ends_at', e.target.value)}
                            placeholder=""
                            id="preview_ends_at"
                            type={auction.is_template ? "time" : "datetime-local"}
                        />
                    </div>

                    <div className="mb-4">
                        <Label>Removal Times</Label>

                        <ReactQuill theme="snow"
                                    className="mt-2"
                                    value={auction.config?.removal_times || ''}
                                    onChange={(v) => setConfig('removal_times', v)}
                        />
                    </div>

                    {canUpdateUnrestricted && (
                        <div className="flex gap-4 items-center">
                            <label
                                className="flex gap-2 items-center mb-4"
                                title="Whether or not this auction is featured."
                            >
                                <input
                                    type="checkbox"
                                    onChange={(e) => setData('is_featured', e.target.checked)}
                                    checked={auction.is_featured}
                                />
                                <span>Featured</span>
                            </label>

                            {!!auction.is_template && (
                                <label
                                    className="flex gap-2 items-center mb-4"
                                    title="Is this a global template? (Accessible to all.)"
                                >
                                    <input
                                        type="checkbox"
                                        onChange={(e) => setData('is_global', e.target.checked)}
                                        checked={auction.is_global}
                                    />
                                    <span>Global</span>
                                </label>
                            )}
                        </div>
                    )}

                    {canUpdateUnrestricted && (
                        <>
                            <TextField
                                extra="mb-4"
                                label="Admin Description (not displayed on the site)"
                                value={auction.description || ''}
                                onChange={(e) => setData('description', e.target.value)}
                                id="description"
                                cols="30"
                                rows="5"
                            />

                            <TextField
                                extra="mb-4"
                                label="Admin Notes"
                                value={auction.notes || ''}
                                onChange={(e) => setData('notes', e.target.value)}
                                id="notes"
                                cols="30"
                                rows="5"
                            />
                        </>
                    )}

                    <TextField
                        extra="mb-4"
                        label={canUpdateUnrestricted ? "Seller Notes" : "Internal Notes"}
                        value={auction.seller_notes || ''}
                        onChange={(e) => setData('seller_notes', e.target.value)}
                        id="seller_notes"
                        cols="30"
                        rows="5"
                    />
                </>
            )}
        </>
    )
}

export default AuctionForm