import { useState } from 'react';
import InputField from 'components/fields/InputField';
import SelectField from 'components/fields/SelectField';
import Button from 'components/buttons/Button';
import { states } from 'services/states';
import { FaPencil } from 'react-icons/fa6';
import AddressAutocomplete from 'components/fields/AddressAutocomplete';

const Location = ({
  location,
  setLocation,
  removeLocation,
  hideButtons = false,
}) => {
  const [editing, setEditing] = useState(location.editing || false);

  const setData = (k, v) => {
    setLocation({
      ...location,
      [k]: v,
    });
  };

  let full_address = location.address || '';

  if (location.city) {
    full_address += ', ' + location.city;
  }

  if (location.state) {
    full_address += ', ' + location.state;
  }

  if (location.zip) {
    full_address += ' ' + location.zip;
  }

  return editing ? (
    <div className="pb-4">
      <div className="mb-2 mt-7 grid grid-cols-2 gap-3">
        <InputField
          label="Nickname (optional)"
          placeholder="e.g. Main Office"
          id="location_name"
          type="text"
          value={location.name || ''}
          onChange={(e) => setData('name', e.target.value)}
        />
        {/* <InputField
                    label="Address"
                    id="address"
                    type="text"
                    value={location.address || ''}
                    onChange={(e) => setData('address', e.target.value)}
                /> */}

        <div className="max-w-full">
          <AddressAutocomplete formData={location} setFormData={setLocation} />
        </div>

        <InputField
          label="City"
          id="city"
          type="text"
          value={location.city || ''}
          onChange={(e) => setData('city', e.target.value)}
        />
        <SelectField
          label="State"
          id="state"
          value={location.state || ''}
          onChange={(e) => setData('state', e.target.value)}
          options={states}
        />
        <InputField
          label="ZIP Code"
          id="zip"
          type="text"
          value={location.zip || ''}
          onChange={(e) => setData('zip', e.target.value)}
        />
        <InputField
          label="Phone Number"
          id="phone"
          type="tel"
          value={location.phone || ''}
          onChange={(e) => setData('phone', e.target.value)}
        />
        <label className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={location.is_default || false}
            onChange={(e) => setData('is_default', e.target.checked)}
          />
          <span>Default Location</span>
        </label>
      </div>

      {!hideButtons && (
        <div className="flex items-center justify-between">
          <Button
            color="red"
            size="sm"
            onClick={(e) => removeLocation(e, location)}
          >
            Remove Location
          </Button>

          <Button
            color="outline"
            size="sm"
            onClick={() => {
              setEditing(false);
            }}
          >
            Done
          </Button>
        </div>
      )}
    </div>
  ) : (
    <div className="flex items-center gap-4 py-2">
      <div>
        {'Location: '}

        {location.name && (
          <>
            {location.name}
            <br />
          </>
        )}

        {full_address}
      </div>
      <div>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setEditing(true);
          }}
          className="flex items-center gap-2"
        >
          <FaPencil className="h-5 w-5" />
          Edit
        </a>
      </div>
    </div>
  );
};

export default Location;
